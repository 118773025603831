import { Label } from '@air/primitive-label';
import { VisuallyHidden } from '@air/primitive-visually-hidden';
import { Slot } from '@radix-ui/react-slot';
import classNames from 'classnames';
import { useField } from 'formik';
import type { ComponentProps, ReactNode } from 'react';

export type FormikFieldProps = ComponentProps<'div'> & {
  component: ReactNode;
  id: string;
  isLabelHidden?: boolean;
  label: string;
  name: string;
};

export const FormikField = ({
  className,
  component,
  id,
  isLabelHidden,
  label,
  name,
  ...restOfProps
}: FormikFieldProps) => {
  const formikId = `formik-field-${id}`;
  const [field, meta] = useField({ name });

  return (
    <div className={classNames('relative flex flex-col gap-1', className)} {...restOfProps}>
      {!!label && isLabelHidden ? (
        <VisuallyHidden>
          <Label htmlFor={formikId} size="small">
            {label}
          </Label>
        </VisuallyHidden>
      ) : (
        <Label htmlFor={formikId} size="small">
          {label}
        </Label>
      )}

      <Slot id={formikId} {...field}>
        {component}
      </Slot>

      {!!meta.error && meta.touched && (
        <div
          data-testid="FORM_ERROR"
          data-fieldid={id}
          className="form-field-error absolute top-full w-full translate-y-1 text-12 text-flamingo-600"
        >
          {meta.error}
        </div>
      )}
    </div>
  );
};
